import React, { useState, useEffect } from 'react';
import './planning-type.scss';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PaginationView from '../../../../components/pagination/pagination.view.jsx';
import ThumbnailPreview from './child/thumbnail-preview.view';

import * as statementActions from '../../../../redux/store/statement/statement.store';
import * as config from '../../../../utils/configuration';
import * as appActions from "../../../../core/app.store";
import * as homePageStore from "../../../../redux/store/home-page/home-page.store";
import PageUrls from '../../../../common/url-collect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';

function PlanningByTypeView(props) {
  const { isLoading, showLoading, params, setDataSelectBoxSearch, selected, planningType, key } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
useEffect(() => {
  console.log('sdhjfiusdjf',location)
},[location.search])
  return (
    <div className="planning-by-type-container position-relative">
      <div className="row">
        {planningType && planningType.length > 0 ? planningType.map((item, index) => (
          <ThumbnailPreview
            key={index}
            backgroundUrl={item.image_Url && item.image_Url !== 'null' ? config.APIUrlDefault + item.image_Url : require('../../../../assets/image/img-default.png')}
            previewName={item.name}
            urlParams={`/ban-do-quy-hoach?type=${queryParams.get('type')}&key=${item.id}`}
          />
        )) : (
          <div className="col-12 text-center text-danger mb-3 mt-5">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-2"
            />{" "}
            Không có quy hoạch nào
          </div>
        )}
      </div>
      {/* <div className="pagination-container list-footer">
        {
          planningDetail && planningDetail.pageCount > 0 && (
            <div className={`row ${planningDetail.pageCount === 1 ? 'justify-content-center' : 'justify-content-between'}`}>
              <div className="text-result">
                <span className="ml-2">Có {planningDetail.totalItemCount} kết quả</span>
              </div>
              {
                planningDetail.pageCount > 1 && (<PaginationView totalItems={planningDetail.totalItemCount}
                  currentIndex={planningDetail.pageIndex}
                  pageSize={planningDetail.pageSize} onClick={getPlanningSearchByIdName} id={new URLSearchParams(params.location.search).get("key")} />)
              }
            </div>
          )
        }
      </div> */}
    </div>
  )
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(PlanningByTypeView);