import React from "react";
import Breadcrumd from "../../components/breadcrumd/breadcrumd";
import { ReactComponent as BuildingIcon } from "./icon/building.svg";
import { ReactComponent as GroupIcon } from "./icon/fa_group.svg";
import { ReactComponent as IdCardIcon } from "./icon/fa-solid_id-card-alt.svg";
import { ReactComponent as TruckIcon } from "./icon/truck 1.svg";
import { ReactComponent as FlagIcon } from "./icon/flag.svg";
import { ReactComponent as GrassIcon } from "./icon/grass-leaves-silhouette 1.svg";
import { ReactComponent as HandIcon } from "./icon/hand.svg";
import { ReactComponent as PineIcon } from "./icon/pine1.svg";
import { ReactComponent as TrophyIcon } from "./icon/trophy.svg";

import "./intro.scss";

const listIntro = [
    {
        title: "Vốn đầu tư nước ngoài",
        description: "Thu hút (Tỷ Đô-la Mỹ)",
        value: "15",
        image: <HandIcon />,
    },
    {
        title: "Quốc gia và vùng lãnh thổ",
        description: "Khách hàng đến từ",
        value: "30",
        image: <FlagIcon />,
    },
    {
        title: "Khách hàng",
        value: "800",
        image: <BuildingIcon />,
    },
    {
        title: "Số lượng người ra vào KCN",
        value: "250,000",
        image: <GroupIcon />,
    },
    {
        title: "Việc làm",
        value: "250,000",
        image: <IdCardIcon />,
    },
    {
        title: "Giải thưởng & chứng nhận",
        value: "80",
        image: <TrophyIcon />,
    },
    {
        title: "Mảng xanh tại VSIP",
        value: "186 HA",
        image: <GrassIcon />,
    },
    {
        title: "Tổng số cây xanh trồng tại VSIP",
        value: "72,000",
        image: <PineIcon />,
    },
    {
        title: "Lượng xe tải và xe trở hàng ra vào KCN mỗi ngày",
        value: "6,100",
        image: <TruckIcon />,
    },
]

const Intro = props => {
    const breadcrumb = [
        {
            title: 'Giới thiệu dự án'
        }
    ]
    return (
        <div className="introduce">
            <Breadcrumd
                pageTitle='Giới thiệu dự án'
                breadcrumbs={breadcrumb}
            />
            <div className="container">
                <div className="intro_content">
                    <div className="row">
                        {listIntro.map((item, index) => (
                            <div key={index} className="col-sm-6 col-lg-4">
                                <div className="card__item">
                                    <div className="card__img">
                                        {item.image}
                                    </div>
                                    <div className="card__body">
                                        <p>{item.description}</p>
                                        <span className="mb-2">{item.value}</span>
                                        <h3>{item.title}</h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="video-ytb">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/0DgDAogZ5go" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;