import React, { useState, useEffect } from 'react';
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction.js';
import { platformModifierKeyOnly } from 'ol/events/condition.js';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import TileWMS from 'ol/source/TileWMS';
import Point from "ol/geom/Point";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import { useMediaQuery } from '@material-ui/core';
import * as config from "../../../utils/configuration";
import * as apiConfig from "../../../api/api-config";
import UrlCollect from "../../../common/url-collect";
import "./home-map.scss";

function OpenLayerHomeView(props) {
    const { dataFeature } = props;
    const [map, setMap] = useState(null);
    const screenIs1024 = useMediaQuery('(max-width: 1024px)');
    const screenIs767 = useMediaQuery('(max-width: 767px)');
    const zoomdefault = 10.5;
    const zoomIs1024 = 9.5;
    const zoomIs767 = 7;
    useEffect(() => {
        const centerDefault = props.defaultCenter || config.defaultMapCenter;
        const defaultZoom = screenIs767 ? zoomIs767 : (screenIs1024 ? zoomIs1024 : zoomdefault);
        const defaultListLayer = props.listLayer || [];
        const defaultExtend = props.extent || [
            config.defaultLeftCornerLng,
            config.defaultLeftCornerLat,
            config.defaultRightCornerLng,
            config.defaultRightCornerLat,
        ];

        const layerDefault = [];
        defaultListLayer.map((layerData) => {
            const layerImage = new TileLayer({
                visible: layerData.is_check,
                zIndex: layerData.z_index,
                minZoom: layerData.min_zoom,
                maxZoom: layerData.max_zoom,
                source: new TileWMS({
                    url: layerData.wms,
                    params: {
                        LAYERS: `qhdongtrieu:${layerData.table}`,
                        LayerId: layerData.id,
                        FORMAT: "image/png",
                        VERSION: "1.1.0",
                        'TILED': true,
                    },
                    tileSize: 512,
                    crossOrigin: 'anonymous'
                }),
                className: `layer-id-${layerData.id}`,
            });
            layerDefault.push(layerImage);
        })

        // Init map
        var initialMap = new Map({
            interactions: defaults({ dragPan: false, mouseWheelZoom: false }).extend([
                new DragPan({
                    condition: function (event) {
                        return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
                    },
                }),
                new MouseWheelZoom({
                    condition: platformModifierKeyOnly,
                }),
            ]),
            target: "map",
            layers: layerDefault,
            view: new View({
                projection: props.projection,
                center: centerDefault,
                extent: defaultExtend,
                zoom: defaultZoom,
                // minZoom: 7,
                // maxZoom: 7,
            }),
        });
        setMap(initialMap);
        functionHandleHover(initialMap);
        initialMap.on("singleclick", function (e) {
            functionHandleClickToDirectDetail(
                initialMap,
                initialMap.getEventPixel(e.originalEvent)
            );
        });
        return () => {
            initialMap.setTarget(null);
        };
    }, [screenIs1024, screenIs767]);

    useEffect(() => {
        const scaleIcon = screenIs767 ? .5 : (screenIs1024 ? .6 : .8);
        if (dataFeature && map) {
            const iconStyle = new Style({
                image: new Icon({
                    src: require('./../../../assets/icon/marker-icon.png'),
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    anchor: [0.5, 64],
                    scale: scaleIcon,
                }),
                cursor: 'pointer'
            });
            const defaultListFeature = dataFeature;
            const featureDefault = [];
            defaultListFeature &&
                defaultListFeature.length > 0 &&
                defaultListFeature.map((feature) => {
                    if (feature.coord) {
                        const featureMarker = new Feature({
                            geometry: new Point(feature.coord),
                            population: 4000,
                            rainfall: 500,
                            name: feature.name,
                            imgUrl: feature.image_Url,
                            id: feature.id
                        });
                        featureDefault.push(featureMarker);
                    }
                });

            const vectorLayer = new VectorLayer({
                source: new VectorSource({
                    features: featureDefault,
                }),
                zIndex: 10,
                style: iconStyle,
            });
            const vectorSource = new VectorSource({
                features: featureDefault
            })
            map.addLayer(vectorLayer);
            vectorSource.forEachFeature(function (feature) {
                var element = document.createElement("div");
                element.style.width = "10px";
                element.style.height = "10px";
                element.style.borderRadius = '50%';
                element.style.border = '2px solid #3B82F4';
                element.style.animation = 'flash 1s infinite';
                element.innerHTML = `
                <div class="flash-animation"></div>
                <div class="flash-animation1"></div>
                `
                var popup = new Overlay({
                    element: element,
                    positioning: "center-center",
                    stopEvent: false,
                    position: feature.getGeometry().getCoordinates(),
                    zIndex: 9,
                });
                map.addOverlay(popup);
            });
        }
    }, [map, dataFeature])
    const functionHandleHover = (map) => {
        if (map) {
            map.on("pointermove", function (e) {
                functionHoverShowTooltip(map, map.getEventPixel(e.originalEvent))
                var hit = this.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                    return true;
                });
                if (hit) {
                    this.getTargetElement().style.cursor = 'pointer';
                } else {
                    this.getTargetElement().style.cursor = '';
                }
            })
        }
    }

    const functionHandleClickToDirectDetail = (map, pixel) => {
        const feature = map?.forEachFeatureAtPixel(pixel, function (
            feature
        ) {
            return feature;
        });
        if (
            feature &&
            feature.getProperties() &&
            feature.getProperties().id
        ) {
            window.open(
                `${UrlCollect.PlanningMapViewDetail}?key=${feature.getProperties().id}`
            );
        }
    };

    const functionHoverShowTooltip = (map, pixel) => {
        const tooltipContainer = document.getElementById(
            "tooltip-openlayer"
        );
        let left = pixel[0] + 20;
        let top = pixel[1] + 20;
        if (tooltipContainer) {
            tooltipContainer.style.left = left + "px";
            tooltipContainer.style.top = top + "px";
            tooltipContainer.style.opacity = "1";
        }

        const feature = map?.forEachFeatureAtPixel(pixel, function (
            feature
        ) {
            console.log(feature)
            return feature;
        });
        if (
            feature &&
            feature.getProperties() &&
            feature.getProperties().name
        ) {
            tooltipContainer.innerHTML = `
                <div> 
                    <img src='${apiConfig.api + feature.getProperties().imgUrl}' alt='${feature.getProperties().name}'/>
                    <p>${feature.getProperties().name}</p>
                </div>
            `
        } else {
            tooltipContainer.style.opacity = "0";
        }
    }

    return (
        <>
            <div
                id="map"
                tabindex="1"
                style={{ width: '100%', height: screenIs1024 ? '490px' : '600px' }}>
            </div>
            <div id="tooltip-openlayer"></div>
        </>
    )
}

export default OpenLayerHomeView
