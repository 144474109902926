import React from "react";
import "./general-planning.scss";
import TopRightPanelView from "../planning-map-view/top-right-panel/top-right-panel.view";
import LeftMenuView from "./left-menu/left-menu.view";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import { GeneralPlanningViewProps, GeneralPlanningViewState, } from "../../models/general-planning-view/props-state.model";
import { isMobile } from 'react-device-detect';
//mui
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from "@material-ui/icons/Info";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
const ListBaseMap: any[] = [
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "https://mt0.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}",
    view_default: true,
    z_index: 0,
    name: "Bản đồ nền đường phố",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "https://mt0.google.com/vt/lyrs=s&hl=vi&x={x}&y={y}&z={z}",
    view_default: true,
    z_index: 0,
    name: "Bản đồ vệ tinh",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
  {
    base_map_setting_id: 0,
    id: 1,
    map_setting_id: 1,
    url: "",
    view_default: true,
    z_index: 0,
    name: "Không nền",
    baseMapSettingModel: {
      id: 0,
      layer_type: "",
      status: true,
      url: "",
    },
  },
];

class GeneralPlanningView extends React.Component<GeneralPlanningViewProps, GeneralPlanningViewState> {
  constructor(props: GeneralPlanningViewProps) {
    super(props);
    this.state = {
      isLeftNavbarHide: true,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      isDisplaySearchResult: false,
      displaySearchLocationBar: false,
      coordinate: "",
      dataTabInfomation: null,
      showInfo: false,
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup = this.closeFilterInfomationPopup.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<GeneralPlanningViewProps>, prevState: Readonly<GeneralPlanningViewState>, snapshot?: any): void {
    if (this.state.dataTabInfomation != prevState.dataTabInfomation) {
      this.setState({ showInfo: true })
      this.handleShowHidePlanningMenu(true)
    }
  }

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    //this.props.GetAllPlanning();
    //this.props.GetAllPlanningCategoriesType();
    //this.props.GetAllPlanningBoundaries();
    if (isMobile) {
      this.handleShowHidePlanningMenu(false);
    }
  }

  handleClickIconLeftNavbar(id: number) {
    this.handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement?.classList.contains("collapsed")) buttonElement.click();
  }

  handleClickToggleLeftNavBar() {
    this.setState({
      isLeftNavbarHide: !this.state.isLeftNavbarHide,
    });
    if (!this.state.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  manualCloseMaptoolsFilterInfomationPopup = () => {
    this.setState({ isShowFilterInfomationPopup: false });
  };

  handleOpenOrCloseMapToolsPanel = () => {
    this.setState({
      isShowMapToolsPanel: !this.state.isShowMapToolsPanel,
    });
  };

  handleShowHidePlanningMenu(isShow: boolean) {
    const LeftMenuElement = document.getElementsByClassName(
      "left-custom-navbar"
    )[0] as HTMLElement;
    if (LeftMenuElement) {
      if (isShow) {
        LeftMenuElement.style.left = "0";
      } else {
        LeftMenuElement.style.left = "-320px";
      }
      this.setState({ isLeftNavbarHide: isShow });
    }
  }

  render() {
    const isLeftNavbarHide = this.state.isLeftNavbarHide;

    return (
      <div className="general-planning-map-view-container position-relative">
        {/* Top right panel*/}
        <div className="general_panel">
          <TopRightPanelView
            isHiddenMapTools={true}
            baseMapList={ListBaseMap}
            isHiddenSearch={true}
            isHiddenLayer={true}
            isHiddenInfor={true}
            isGeneralPlanning={true}
          />
        </div>

        {/* Open layer view port */}
        <OpenlayersMapView
          listPlanningType={this.props.planningType}
          projection={"EPSG:4326"}
          isGeneralPlanning={true}
          coordinate={this.props.location}
          setDataTabInfomation={(data: any) => this.setState({ dataTabInfomation: data })}
          dataTabInfomation={this.state.dataTabInfomation}
        />

        {/* Left nav bar */}
        <div className="left-custom-navbar position-absolute h-100">
          {/* <SelectCurrentLayerView listLayers={ExtracAllLayersArrays(this.props.mapData)} /> */}
          <button
            onClick={() => {
              // this.setState({ isLeftNavbarHide: !this.state.isLeftNavbarHide })
              this.handleShowHidePlanningMenu(!this.state.isLeftNavbarHide)
            }}
            className="custom-button-left-menu btn position-absolute"
            title="Các lớp quy hoạch"
          >
            {this.state.isLeftNavbarHide ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}

          </button>

          <div className="left_menu_button">
            <div className={`left_menu_button_item ${!this.state.showInfo ? 'active' : ''}`}>
              <Tooltip title="Menu"
                onClick={() => {
                  this.setState({ showInfo: false })
                  this.handleShowHidePlanningMenu(true)
                }}
              >
                <IconButton
                  aria-label="Menu"
                >
                  <MenuIcon color={!this.state.showInfo ? 'primary' : 'disabled'} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={`left_menu_button_item ${this.state.showInfo ? 'active' : ''}`}>
              <Tooltip title="Thông tin"
                onClick={() => {
                  this.setState({ showInfo: true })
                  this.handleShowHidePlanningMenu(true)
                }}
              >
                <IconButton
                  aria-label="Thông tin"
                >
                  <InfoIcon color={this.state.showInfo ? 'primary' : 'disabled'} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <LeftMenuView
            resetSearchDisplayMode={() => this.setState({ isDisplaySearchResult: false })}
            isDisplaySearchResult={this.state.isDisplaySearchResult}
            dataTabInfomation={this.state.dataTabInfomation}
            showInfo={this.state.showInfo}
            setShowInfo={(data: any) => this.setState({ showInfo: data })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
  listPlanning: state.mapData.listPlanning,
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  planningType: state.statement.planningType,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      GetAllPlanningCategoriesType: MapDataStore.GetAllPlanningCategoriesType,
      GetAllPlanning: MapDataStore.GetAllPlanning,
      GetAllPlanningBoundaries: MapDataStore.GetAllPlanningBoundaries,
      SaveHeaderForTable: MapDataStore.SaveHeaderForTable,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralPlanningView);
