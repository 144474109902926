import React, { useEffect, useState } from "react";
import "./look-up-planning.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SearchByAddress from "./child-modules/search-address/search-address.view";
import SearchPlanning from "../../components/search-planning/search-planning.view"
import PlanningByTypeView from "./child-modules/planning-type/planning-type.view";
import PlanningIntroduceView from "./child-modules/planning-introduce/planning-introduce.view";
import UrlCollect from "../../common/url-collect";
import SearchPlanningView from "./child-modules/search-planning-name/search-planning-name.view";
import * as statementActions from "../../redux/store/statement/statement.store";
import history from "../../common/history";
import * as mapHelper from "../../common/map";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import * as homePageStore from "../../redux/store/home-page/home-page.store";
import uongBiCoordinate from "../../common/uongbi_coordinate";
import { GetAllPlannningType } from "../../redux/store/home-page/home-page.store";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import { useLocation } from 'react-router-dom';

function LookUpPlanningView(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [planningType, setPlanningType] = useState([]);
  const [data, setData] = useState();
  const [dataSelectBoxSearch, setDataSelectBoxSearch] = useState([]);
  const [selected, setSelected] = useState();
  const [show, setShow] = useState(true);
  let commentInput = null;
  const kindId = queryParams.get("type");
  const key = queryParams.get("key");  

  useEffect(() => {
    const kindId = queryParams.get("type");
    const key = queryParams.get("key");  
    if (kindId) getAllPlanningType(kindId);
    if(key) GetDetailPlannningType(key);
  }, [location.search]);

  const getAllPlanningType = (kindId) => {
    GetAllPlannningType(kindId)
      .then((res) => {
        if (res && res.content) {
          setPlanningType(res.content);
        }
      })
      .catch((err) => {
      });
  };

  const GetDetailPlannningType = (id) => {
    return homePageStore.GetDetailPlannningType(id).then(res => {
        setData(res && res.content ? res.content : {})
    }).catch(err => {})
}


  let PATHNAME =
    "?key=" + new URLSearchParams(props.location.search).get("key");

  function createClassListLeftSelectMenu(pathName) {
    return PATHNAME === pathName ? "p-2 active-custom" : "p-2";
  }

  return (
    <div className="container look-up-planning-container">
      <div className="row">
        <div className="col-12 col-xl-3 left-menu">
          <SearchPlanning />
          <div className="list-select-side-menu">
            <Link
              key={"SyntheticPlanning"}
              to={UrlCollect.SyntheticPlanning}
              className={createClassListLeftSelectMenu(
                UrlCollect.SyntheticPlanning
              )}
            >
              Tổng hợp dự án
            </Link>
            <Link
              to='/ban-do-quy-hoach?type=1'
              className={`p-2 project_type ${kindId == 1 ? 'active' : ''}`}
            >
              Khu công nghiệp
            </Link>
            <Link
              to='/ban-do-quy-hoach?type=2'
              className={`p-2 project_type ${kindId == 2 ? 'active' : ''}`}
            >
              Khu đô thị & dịch vụ
            </Link>
            {/* {planningType &&
              planningType.length > 0 &&
              planningType.map((item, index) => (
                <Link
                  key={index}
                  to={UrlCollect.PlanningMap + "?key=" + item.id}
                  className={createClassListLeftSelectMenu("?key=" + item.id)}
                >
                  {item.name}
                </Link>
              ))} */}
          </div>
        </div>
        <div className="col-12 col-xl-9">
          {kindId && !key ? (
            <PlanningByTypeView
              params={props}
              setDataSelectBoxSearch={setDataSelectBoxSearch}
              selected={selected}
              planningType={planningType}
            />
          ):(
            <PlanningIntroduceView
              params={props}
              setDataSelectBoxSearch={setDataSelectBoxSearch}
              selected={selected}
              data={data}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});
export default connect(mapStateToProps)(LookUpPlanningView)
