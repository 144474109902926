import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import OpenLayerHomeView from "./openLayer-home.view";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";

import {
    ExtractSelectedBasemap,
    ExtractCenterArray,
    ExtractExtentArray,
    ExtractListLayerArray,
    ExtracLayerListGroup,
} from "../../../common/extract-information-from-map-data";
function HomeMap(props) {
    const { showLoading } = props;
    const [listPlanningType, setListPlanningType] = useState();
    const planningId = 43;

    useEffect(() => {
        props.GetMapDetail(planningId);
        GetPlanningType();
    }, [])

    const GetPlanningType = () => {
        showLoading(true);
        homePageStore.GetAllPlannningType().then(res => {
            let data = [];
            if (res && res.content && res.content.length > 0) {
                res.content.map((item) => {
                    item.id === 1 && data.push({ ...item, coord: [105.6899, 10.1154] });
                    item.id === 2 && data.push({ ...item, coord: [105.716207, 10.101290] });
                    item.id === 3 && data.push({ ...item, coord: [105.4626, 10.2809] });
                    item.id === 4 && data.push({ ...item, coord: [105.8178, 9.9868] });
                    item.id === 5 && data.push({ ...item, coord: [105.814540, 10.007367] });
                    item.id === 10 && data.push({ ...item, coord: [105.4914379, 10.31168351] });
                    item.id === 13 && data.push({ ...item, coord: [105.835251, 9.980213] });
                })
                setListPlanningType(data)
            }
            showLoading(false)
        }).catch(err => showLoading(false))
    }
    return (
        <>
            {/* {props.mapData.name && (
                <OpenlayersMapView
                    defaultBaseMapUrl={ExtractSelectedBasemap(props.mapData)}
                    defaultCenter={ExtractCenterArray(props.mapData)}
                    defaultZoom={props.mapData.zoom}
                    projection={props.mapData.projection}
                    maxZoom={props.mapData.max_zoom}
                    minZoom={props.mapData.min_zoom}
                    extent={ExtractExtentArray(props.mapData)}
                    listLayer={ExtractListLayerArray(props.mapData)}
                    listGroupLayer={ExtracLayerListGroup(props.mapData)}
                />
            )} */}
            {props.mapData.name && (
                <OpenLayerHomeView
                    defaultBaseMapUrl={ExtractSelectedBasemap(props.mapData)}
                    defaultCenter={ExtractCenterArray(props.mapData)}
                    defaultZoom={props.mapData.zoom}
                    projection={props.mapData.projection}
                    maxZoom={props.mapData.max_zoom}
                    minZoom={props.mapData.min_zoom}
                    extent={ExtractExtentArray(props.mapData)}
                    listLayer={ExtractListLayerArray(props.mapData)}
                    listGroupLayer={ExtracLayerListGroup(props.mapData)}
                    dataFeature={listPlanningType}
                />
            )}

        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    hasLoadedMapData: state.mapData.hasLoadingData,
    mapData: state.mapData.mapData,
    planningRelationShips: state.mapData.planningRelationShips,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HomeMap);