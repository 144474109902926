import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";

const service = new Service();

const PLANNING_TYPE = "PLANNING_TYPE";

const createPlanningType = (data) => ({
  type: PLANNING_TYPE,
  data: data,
});

export const GetStatementByPlanning = (planningId) => {
  const params = new URLSearchParams();
  params.append("planningId", planningId);
  return service
    .get(ApiUrl.GetStatementByPlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetListStatement = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = "",
  email = ""
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  email && params.append("title", email);
  return service
    .get(ApiUrl.GetListStatement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailStatement = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.GetDetailStatement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningType = () => {
  return service
    .get(ApiUrl.PlanningType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const listPlanningDemo = [
  {
    id: 1111,
    name: 'VSIP I, Bắc Ninh',
    coord: [105.96031536712411, 21.07825294423165]
  },
  {
    id: 1111,
    name: 'KCN VSIP I, Bình Dương',
    coord: [106.71359022984842, 10.924522725881639]
  },
  {
    id: 1111,
    name: 'KCN VSIP II, Bình Dương',
    coord: [106.6842316582708, 11.073997110649271]
  },
  {
    id: 1111,
    name: 'KCN VSIP III, Bình Dương',
    coord: [106.77768287527235, 11.132359998712623]
  },
  {
    id: 1111,
    name: 'VSIP II, Bắc Ninh',
    coord: [105.93152633025278, 21.223826540094848]
  },
  {
    id: 1111,
    name: 'VSIP Hải Phòng',
    coord: [106.67299948567627, 20.905448252471956]
  },
  {
    id: 1111,
    name: 'VSIP Quảng ngãi',
    coord: [108.79161745778771, 15.1566309069491]
  },
  {
    id: 1111,
    name: 'VSIP Hải Dương',
    coord: [106.1730134115045, 20.92977068285253]
  },
  {
    id: 1111,
    name: 'VSIP Nghệ An',
    coord: [105.62852983425672, 18.70685113755795]
  },
  {
    id: 1111,
    name: 'VSIP Bình Định',
    coord: [109.08889777606358, 13.748503730774056]
  },
]

export const PlanningTypeDispatch = () => {
  return (dispatch) => {
    return service
      // .get(ApiUrl.PlanningType)
      .get(ApiUrl.GetAllPlannningType)
      .then((res) => {
        if (res && res.content) {
          // let data = listPlanningDemo;
          // res.content.map((item) => {
          //   if (item.id === 1) data.push({ ...item, coord: [105.6899, 10.1154] });
          //   else if (item.id === 2) data.push({ ...item, coord: [105.716207, 10.101290] });
          //   else if (item.id === 3) data.push({ ...item, coord: [105.4626, 10.2809] });
          //   else if (item.id === 4) data.push({ ...item, coord: [105.8217, 9.9972] });
          //   else if (item.id === 5) data.push({ ...item, coord: [105.8129, 10.007367] });
          //   else if (item.id === 10) data.push({ ...item, coord: [105.4914379, 10.31168351] });
          //   else if (item.id === 13) data.push({ ...item, coord: [105.8374, 9.9797] });
          //   else data.push({ ...item });
          // })
          dispatch(createPlanningType(res.content || []));
        }
        // dispatch(createPlanningType((res && res.content) || []));
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const ApprovalAgencyLevel = () => {
  return service
    .get(ApiUrl.ApprovalAgencyLevel)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetSerivceLink = () => {
  return service
    .get(ApiUrl.ServiceLink)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const SearchPlanning = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = "",
  data
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  data && data.name && params.append("name", data.name);
  data && data.planningTypeId && params.append("planningTypeId", data.planningTypeId);
  data && data.place && params.append("place", data.place);
  data && data.numberOfDecisions && params.append("numberOfDecisions", data.numberOfDecisions);
  data && data.yearOfApproval && params.append("yearOfApproval", data.yearOfApproval);
  data && data.approvalAgencyLevelId && params.append("approvalAgencyLevel", data.approvalAgencyLevelId);
  data && data.approvalAgency && params.append("approvalAgency", data.approvalAgency);
  data && data.planningAgency && params.append("planningAgency", data.planningAgency);
  data && data.consultingUnit && params.append("consultantUnit", data.consultingUnit);
  return service
    .get(ApiUrl.SearchStatement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetPlanningTypeById = (
  id,
  pageIndex = 1,
  pageSize = 6,
  sortExpression = ""
) => {
  const params = new URLSearchParams();
  params.append("id", id);
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);

  return service
    .get(ApiUrl.PlanningTypeById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetPlanningById = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.GetPlanningById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetAllPlanningByTypeId = (planningTypeId) => {
  const params = new URLSearchParams();
  params.append("planningTypeId", planningTypeId);
  
  return service
    .get(ApiUrl.GetAllPlanningByTypeId, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetDetailPlanningById = (planningId) => {
  const params = new URLSearchParams();
  params.append("id", planningId);
  return service
    .get(ApiUrl.GetDetailPlanningById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const InitState = {
  planningType: [],
};

export default function StatementReducer(state = InitState, action) {
  switch (action.type) {
    case PLANNING_TYPE:
      return { ...state, planningType: action.data };
    default:
      return state;
  }
}
