import React from "react";
import { Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import history from "./common/history";
import UrlCollect from "./common/url-collect";


//--- Loading
import AppLoading from "./components/loading/loading.view";

//--- Layout
import LayoutDesktopView from "./components/layout/layout.view.jsx";
import LayoutUserDesktopView from "./components/layout/layout-user.view.jsx";
import LayoutMapPageView from "./components/layout-map-page/layout-map-page.view.jsx";

//--- Homepage
import HomeDesktop from "./modules/home/home.view.jsx";
import RecordsManagementDesktopView from "./modules/records-management/records-management.view.jsx";
import DocumentManagementDesktopView from "./modules/document/list.view.jsx";
import ContactDesktopView from "./modules/contact/contact.view.jsx";
import LookUpPlanningView from "./modules/look-up-planning/look-up-planning.view";
import PlanningMapView from "./modules/planning-map-view/planning-map-view.view.tsx";

//-- User
import LoginDesktop from "./modules/login/login.view.jsx";
import RegisterDesktop from "./modules/register/register.view.jsx";
import ForgotPasswordDesktop from "./modules/forgot-password/forgot-password.view.jsx";
import ResetPasswordDesktop from "./modules/reset-password/reset-password.view";

//--- Xin y kien cong dong
import PleaseConsultTheCommunityDesktopView from "./modules/please-consult-the-community/please-consult-the-community.view.jsx";
import PleaseCommentView from "./modules/please-consult-the-community/please-comment/please-comment.view.jsx";
import SummaryIdeasView from "./modules/please-consult-the-community/summary-ideas/summary-ideas.view.jsx";

//-- Tin tuc

import NewsListView from "./modules/news/list.view.jsx";
import ListIncentivesView from "./modules/news/list-incentives.view.jsx";
import ListAdminProceduresView from "./modules/news/list-admin-procedures.view.jsx";
import NewsDetailView from "./modules/news/detail.view.jsx";
import Intro from "./modules/intro/intro.jsx";

//--- Cong bo quy hoach
import PlanningIntroView from "./modules/planning-intro/planning-intro.view.jsx";
import PlanningAnnouncementDesktopView from "./modules/planning-announcement/planning-announcement.view.jsx";
import ListNewsView from "./modules/news/list.view";
import PlanningAnnouncementDetailDesktopView from "./modules/planning-announcement/planning-announcement-detail/planning-announcement-detail.view.jsx";
import GeneralPlanningView from './modules/general-planning/general-planning.view.tsx';

//--- Error pages
import ErrorPage500 from './modules/error-page/page-500/page-500';

function App() {
  return (
    <div>
      <AppLoading />

      <Router history={history}>
        <Switch>
          {/* Desktop */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={HomeDesktop}
            path={UrlCollect.Home}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PleaseConsultTheCommunityDesktopView}
            path={UrlCollect.PleaseConsultTheCommunity}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningAnnouncementDesktopView}
            path={UrlCollect.PlanningAnnouncement}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningIntroView}
            path={UrlCollect.PlanningIntro}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={DocumentManagementDesktopView}
            path={UrlCollect.DocumentManager + '/:id'}
          />
          {/* Tin tuc */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={NewsDetailView}
            path={UrlCollect.NewsDetail + "/:id"}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={Intro}
            path={UrlCollect.Intro}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={NewsListView}
            path={UrlCollect.NewsList}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ListIncentivesView}
            path={UrlCollect.IncentivesList}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ListAdminProceduresView}
            path={UrlCollect.AdminProceduresList}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={RecordsManagementDesktopView}
            path={UrlCollect.RecordsManager}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ContactDesktopView}
            path={UrlCollect.Contact}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={GeneralPlanningView}
            path={UrlCollect.SyntheticPlanning}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={LookUpPlanningView}
            path={UrlCollect.PlanningMap}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={PlanningMapView}
            path={UrlCollect.PlanningMapViewDetail + ':id'}
          />

          {/* User Desktop */}
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={LoginDesktop}
            path={UrlCollect.Login}
          />
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={RegisterDesktop}
            path={UrlCollect.Register}
          />
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={ForgotPasswordDesktop}
            path={UrlCollect.ForgotPassword}
          />
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={ResetPasswordDesktop}
            path={UrlCollect.ResetPassword + "/:userId"}
          />

          {/* Xin y kien cong dong */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={SummaryIdeasView}
            path={UrlCollect.SummaryIdeas}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PleaseCommentView}
            path={UrlCollect.PleaseComment + "/:id"}
          />

          {/* Cong bo quy hoach */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningAnnouncementDetailDesktopView}
            path={UrlCollect.PlanningAnnouncement + "/:id"}
          />

          {/* error pages */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ErrorPage500}
            path={UrlCollect.Page500 + ":id"}
          />
          {/* lap */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
