import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HomeNewsView from './news/news.view';
import HomeSliderView from "./slider/slider.view";
import HomeSearchView from "./search/search.view"
import HomeLinkView from "./link/link.view"
import HomePlanningView from "./planning/planning.view"
import "./home.scss";

function HomeDesktop(props) {
  const { showLoading } = props;
  return (
    <div className="home-page">
      <HomeSliderView />
      {/* <HomePlanningView /> */}
      <HomeNewsView />
    </div>
  );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default  (connect(mapStateToProps, mapDispatchToProps)(HomeDesktop));
