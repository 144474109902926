import React from 'react';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Vilanguage from '../../languages/vi';

const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
    render() {
        return (
            <>
                <footer className="footer-container">
                    <div className="container">
                        <div class="top-main">
                            <img class="logo" src={require("../../assets/image/logo-footer.png")} alt="logo-footer" />
                            <div class="info">
                                <div class="info-item place">
                                    <img src={require('../../assets/icon/place-footer.svg')} alt="place" />
                                    <p>{Vilanguage.footer_address}</p>
                                </div>
                                <div class="info-item contact">
                                    <div class="phone contact-item">
                                        <img src={require('../../assets/icon/phone-footer.svg')} alt="phone" />
                                        <p>{Vilanguage.footer_hotline}</p>
                                    </div>
                                    <div class="landline contact-item">
                                        <img src={require('../../assets/icon/landline-footer.svg')} alt="landline" />
                                        <p>{Vilanguage.footer_fax}</p>
                                    </div>
                                    <div class="mail contact-item">
                                        <img src={require('../../assets/icon/mail-footer.svg')} alt="mail" />
                                        <p>{Vilanguage.footer_email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>
                <div className="copyright">
                    <div className="container">
                        @ 2020 Copyright by VSIP
                    </div>
                </div>
            </>
        )
    }
}
