import React from "react";
import { APIUrlDefault } from "../../utils/configuration";
import { Link } from "react-router-dom";

const NewsCard = props => {
    const { data, hasDescription } = props;

    return (
        <div className="news_card">
            <Link to={`/tin-tuc-chi-tiet/${data.id}`}>
                <img 
                src={APIUrlDefault + data.image_Url} 
                alt={data.title}
                onError={(e) =>
                    (e.target.src = require("../../assets/image/img-default.png"))
                }

                />
            </Link>
            <Link to={`/tin-tuc-chi-tiet/${data.id}`} className="title">
                {data.title}
            </Link>
            {hasDescription && (
                <div className="description">
                    {data.description}
                </div>
            )}
        </div>
    )
}

export default NewsCard;