import React from "react";
import { Link } from "react-router-dom";
import BreadcrumdIcon from "../../assets/icon/breadcrumb-icon.svg";

import "./breadcrumb.scss"

const Breadcrumd = props => {
    const { pageTitle, breadcrumbs } = props;

    return (
        <div className="breadcrumb_">
            <div className="container">
                <div className="breadcrumb-container">
                    <h3 className="current">{pageTitle}</h3>
                    <div className="breadcrumb-main">
                        <Link to="/" className="breadcrumb-item">
                            Trang chủ
                        </Link>
                        <div className="breadcrumd-icon">
                            <img src={BreadcrumdIcon} alt="BreadcrumdIcon" />
                        </div>
                        {breadcrumbs && breadcrumbs.map((item, index) => (
                            <Link to={item.link} className="breadcrumb-item">
                                {item.title}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumd