import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as planningAction from "../../redux/store/planning/planning.store";
import * as appActions from "../../core/app.store";
import history from "../../common/history";
import ShowNotification from "../react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import AsyncSelect from 'react-select/async';
import UrlCollect from "../../common/url-collect";
import "./search-planning.scss"
var query = null;

function PlanningSearch(props) {
    const { showLoading } = props;

    const [coord, setCoord] = useState()
    const [isOpenNameSearch, setIsOpenNameSearch] = useState(false);
    const [isOpenXySearch, setIsOpenXySearch] = useState(true);
    const [isOpenLatLngSearch, setIsOpenLatLngSearch] = useState(false);
    const [isOpenAddressSearch, setIsOpenAddressSearch] = useState(false);
    const [currentPosition, setCurrentPosition] = useState({
        latitude: null,
        longitude: null,
        altitude: null,
        accuracy: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
    });
    const customStyles = {
        control: () => ({
            border: 'none',
            height: '28px',
        }),
    };

    const onGetCurrentPosition = () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentPosition(position.coords);
                    resolve(position);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    };

    const loadOptions = (inputValue, callback) => {
        if (query) {
            clearTimeout(query);
        }

        if (inputValue.length > 2) {
            query = setTimeout(() => {
                // return locationSvc.getLookupAutoComplete(params).then(({ content }) => {
                if (inputValue) {
                    if (isOpenNameSearch) {
                        const getListProjects = async () => {
                            let results = [];
                            const res = await planningAction.GetListAll(1, 100, inputValue);
                            if (res.content.items && res.content.items.length > 0) {

                                results = res.content.items.map(item => {
                                    return { ...item, value: item.id, label: item.name };
                                })
                            }
                            if (results.length == 0) {
                                ShowNotification(
                                    'Vui lòng nhập tên khu công nghiệp',
                                    NotificationMessageType.Warning,
                                    'center'
                                );
                            }
                            callback(results);
                        };
                        getListProjects();
                    } else if (isOpenAddressSearch) {
                        const getListAddress = async () => {
                            let results = [];
                            const res = await planningAction.GetLatLngPlace(inputValue);
                            if (res.result && res.result.length > 0) {
                                results = res.result.map(item => {
                                    return { ...item, value: item.id, label: item.name };
                                })
                            }
                            if (results.length == 0) {
                                ShowNotification(
                                    'Vui lòng nhập tên khu công nghiệp',
                                    NotificationMessageType.Warning,
                                    'center'
                                );
                            }
                            callback(results);
                        };
                        getListAddress();
                    }
                }
            }, 800);
        } else {
            callback([]);
        }
    };

    const handleChoose = (input) => {
        console.log(input)
        if (isOpenNameSearch) {
            let url = 'ban-do-quy-hoach?key=' + input.planningTypeId;
            history.push(url)
        } else {
            GetPlanningByCoord(input.location.lng, input.location.lat);
        }
    }

    const handleClickCurrentLocation = () => {
        let currentPositionTemp = currentPosition;

        if (!currentPositionTemp) {
            navigator.geolocation.getCurrentPosition((position) => {
                currentPositionTemp = currentPosition;
            });
        }
        if (currentPositionTemp.latitude && currentPositionTemp.longitude) {
            GetPlanningByCoord(currentPositionTemp.longitude, currentPositionTemp.latitude);
        }
    }

    const GetPlanningByCoord = (lng, lat) => {
        planningAction.GetPlanningByCoord(lng, lat).then(res => {
            if (res.content === 0) {
                ShowNotification(
                    'Không có dự án nào nằm tại vị trí của bạn!',
                    NotificationMessageType.Warning,
                    'center'
                );
            } else {
                history.push(
                    `${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
                )
            }
        }).catch(err => { console.log(err) })
    }

    const pressKey = (event) => {
        if (event.keyCode == 13 && coord) {
            let coordinateList = coord.split(',');
            if (coordinateList && coordinateList.length === 2) {
                let x = parseFloat(coordinateList[0]);
                let y = parseFloat(coordinateList[1]);
                if (isOpenXySearch) {
                    planningAction.GetVn2000ToWgs84(x, y).then((coords) => {
                        GetPlanningByCoord(coords[0], coords[1]);
                    })
                } else {
                    GetPlanningByCoord(y, x);
                }

            } else {
                ShowNotification(
                    'Vui lòng nhập dúng định dạng tọa độ',
                    NotificationMessageType.Warning,
                    'center'
                );
            }
        }
    }

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetCurrentPosition(),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const handleChooseSearchType = (type) => {
        if (type === 'isAddress') {
            setIsOpenAddressSearch(true);
            setIsOpenNameSearch(false);
            setIsOpenXySearch(false);
            setIsOpenLatLngSearch(false);
        } else if (type === 'isXy') {
            setIsOpenAddressSearch(false);
            setIsOpenNameSearch(false);
            setIsOpenXySearch(true);
            setIsOpenLatLngSearch(false);
        } else if (type === 'isLatLng') {
            setIsOpenAddressSearch(false);
            setIsOpenNameSearch(false);
            setIsOpenXySearch(false);
            setIsOpenLatLngSearch(true);
        } else {
            setIsOpenAddressSearch(false);
            setIsOpenNameSearch(true);
            setIsOpenXySearch(false);
            setIsOpenLatLngSearch(false);
        }
    }

    return (
        <>
            <div className="banner_search">
                {/* <div
                    className={`search_icon ${isOpenNameSearch ? 'active' : ''}`}
                    onClick={() => handleChooseSearchType('isName')}
                >
                    <img className="" src={require('../../modules/home/slider/fi_search.png')} alt="" />
                </div> */}
                <div
                    className={`search_icon ${isOpenXySearch ? 'active' : ''}`}
                    onClick={() => handleChooseSearchType('isXy')}
                >
                    <img className="" src={require('../../modules/home/slider/vector.png')} alt="" />
                </div>
                <div
                    className={`search_icon ${isOpenLatLngSearch ? 'active' : ''}`}
                    onClick={() => handleChooseSearchType('isLatLng')}
                >
                    <img className="" src={require('../../modules/home/slider/fi_latlng.png')} alt="" />
                </div>
                <div
                    className={`search_icon ${isOpenAddressSearch ? 'active' : ''}`}
                    onClick={() => handleChooseSearchType('isAddress')}
                >
                    <img className="" src={require('../../modules/home/slider/fi_map-pin.png')} alt="" />
                </div>
                <div
                    className={`search_icon`}
                    onClick={() => handleClickCurrentLocation()}
                >
                    <img className="" src={require('../../modules/home/slider/locate-icon.png')} alt="" />
                </div>
            </div>
            <div className="search_input">
                {(isOpenNameSearch || isOpenAddressSearch) ? (
                    <AsyncSelect
                        className='search_input_select'
                        placeholder={isOpenNameSearch ? 'Nhập tên khu công nghiệp để tìm kiếm' : 'Nhập địa chỉ để tìm kiếm'}
                        isLoading={false}
                        loadOptions={loadOptions}
                        onChange={data => {
                            handleChoose(data);
                        }}
                        components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                        styles={customStyles}
                        noOptionsMessage={() => isOpenNameSearch ? 'Nhập tên khu công nghiệp để tìm kiếm' : 'Nhập địa chỉ để tìm kiếm'}
                    />
                ) : (
                    <input
                        className="search_input_coord"
                        type="text"
                        placeholder={isOpenXySearch ? "Nhập tọa độ VN2000 (X,Y)" : "Nhập tọa độ WGS84 (Lat, Lng)"}
                        onChange={e => setCoord(e.target.value)}
                        onKeyDown={pressKey}
                    />
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(PlanningSearch));