import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListAll = async (
  pageIndex = 1,
  pageSize = 100,
  // sort,
  planningName,
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  // sort && params.append("sort", sort);
  planningName && params.append("planningName", planningName);
  try {
    const res = await service.get(ApiUrl.GetListAll, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetLatLngPlace = async (address) => {
  const params = new URLSearchParams();
  params.append("key", "ac543d442b430dcfbe8ed6d89fe5e0d8");
  address && params.append("text", address);
  try {
    const res = await service.get("https://api.map4d.vn/sdk/autosuggest", params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const GetVn2000ToWgs84 = (x, y) => {
  const params = new URLSearchParams();
  x && params.append("x", x);
  y && params.append("y", y);
  return service
    .get(ApiUrl.GetVn2000ToWgs84, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetPlanningByCoord = (longtitude, latitude) => {
  const params = new URLSearchParams();
  longtitude && params.append("longtitude", longtitude);
  latitude && params.append("latitude", latitude);
  return service
    .get(ApiUrl.GetPlanningByCoord, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}