import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../../core/app.store";
import * as newsActions from "../../../redux/store/news/news.store";

import {
  APIUrlDefault,
  getUserInfo,
} from "../../../utils/configuration";
import dateformat from "dateformat";
import NewsCard from "../../../components/news-card/news-card";
import "./news.scss";

function HomeNewsView(props) {
  const { showLoading } = props;

  const [newsModel, setNewsModel] = useState([]);
  const [hotNews, setHotNews] = useState({});
  const [pagingNews, setPagingNews] = useState();
  const [currentPage, setCurrentPage] = useState();
  const pageSize = 4;
  const categoryId = 1;

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListNews({
        categoryId: categoryId,
        pageIndex: 1,
        pageSize: pageSize
      }),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetListNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListNews(data).then(
        (res) => {
          setNewsModel(
            res &&
              res.content &&
              res.content.items
              && res.content.items.length > 0 ? res.content.items : []
          );
          setPagingNews(res.content);
          setCurrentPage(res.content.pageIndex);
          setHotNews(res.content.items[0]);

          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  const getNextPage = () => {
    let nextPage = 1;
    if (currentPage > 0) {
      nextPage = currentPage + 1;
    }
    onGetListNews({
      categoryId: categoryId,
      pageIndex: nextPage,
      pageSize: pageSize
    })
  }

  const getPreviousPage = () => {
    let prePage = 1;
    if (currentPage > 1) {
      prePage = currentPage - 1;
    }
    onGetListNews({
      categoryId: categoryId,
      pageIndex: prePage,
      pageSize: pageSize
    })
  }

  return (
    <div>
      <section className="home_news">
        <div className="container">
          <div className="header_home_title">
            <div className="title">
              <h3>TIN MỚI NHẤT</h3>
            </div>
            <Link className="see_more" to="/tin-tuc">
              Xem thêm <img src={require('../../../assets/icon/arrow-right.svg')} />
            </Link>
          </div>
          <div className="row home_new_content">
            {newsModel && newsModel.length > 0 && newsModel.map(item => (
              <div key={item.id} className="col-sm-6 col-lg-3">
                <NewsCard data={item} hasDescription />
              </div>
            ))}

          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  listPolygon: state.mapData.listPolygon
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(HomeNewsView));
