import React, { useEffect, useState } from "react";
import './guide-use-map.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import * as statementAction from "../../../redux/store/statement/statement.store";

export default function GuideUseMap(props) {
    const { planningId } = props;
    const [statement, setStatement] = useState({});

    useEffect(() => {
        statementAction
            .GetStatementByPlanning(planningId)
            .then(
                (res) => {
                    res &&
                        res.content &&
                        setStatement(res.content);
                    console.log(res.content);
                }
            )
            .catch((err) => { });
    }, [planningId]);

    return (
        <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div
                    className="modal-content modal-content-custom"
                    style={{ height: `${props.modalHeightStyle}px` }}
                >
                    {
                        statement && (
                            <div className="modal-content-header text-center position-relative">
                                Giới thiệu: {statement?.title}
                                <FontAwesomeIcon
                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    icon={faTimes}
                                    color="white"
                                    size="lg"
                                />
                            </div>
                            )
                    }
                    {
                        statement ? (
                            <div class="modal-body news">
                                <div className="new-details">
                                    <p className="new-desc" dangerouslySetInnerHTML={{ __html: statement.brief }}></p>
                                    <p className="new-content" dangerouslySetInnerHTML={{ __html: statement.content }}></p>
                                </div>
                          </div>
                            
                            ) : "Chưa có dữ liệu"
                    }
                </div>
            </div>
        </div>
    )
}